import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "../styles/a-propos.module.css"

const About: React.FC = () => {
  return (
    <Layout location="a-propos" theme="light">
        <SEO title="KPAJ - À propos" />
        <div className={styles.container}>
            <div className={styles.leftContent}>
                <h1>À propos</h1>
            </div>
            <div className={styles.rightContent}>
                <h1>À propos</h1>
                <p>
                    KPAJ est fier de distribuer des produits réalisés par des travailleurs dans le cadre du Conseil québécois des entreprises adaptées (C.Q.E.A.).
                    <br/><br/>
                    Le C.Q.E.A. veille à faciliter l’accessibilité à l’emploi à des personnes souffrant de limitations d’ordre physique, mental et/ou intellectuel. Le Conseil offre à ses membres un travail adapté à leur limitation, leur permettant ainsi de développer leur potentiel et leur autonomie dans un environnement de travail exempt de pression de rentabilité.
                    <br/><br/>
                    Chaque fois que vous achetez un produit distribué par KPAJ, vous encouragez le développement de l’emploi et l’intégration sociale de personnes souffrant de limitations fonctionnelles.
                    <br/><br/>
                    Merci de votre support!
                    <br/>
                    KPAJ
                </p>
            </div>  
        </div>
    </Layout>
  )
}

export default About